import React, { useEffect } from "react";
import { graphql, Link } from "gatsby";
import styled from "styled-components";
import { Helmet } from "react-helmet";

// Previews
import { withPreview } from "gatsby-source-prismic";

// Utils
import { ImageOrientation } from "../utils/image-orientation";

// Context
import { PageTypeConsumer } from "../components/context/page-type";

// Components
import { FilteredStills } from "../components/filters/filtered-stills";

const Container = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  width: 100%;

  padding: 190px 100px 0 100px;

  max-width: 1400px;
  margin: 0 auto;

  @media (max-width: 1200px) {
    padding: 190px 50px 0 50px;
  }

  @media (max-width: 768px) {
    padding: 110px 20px 0 20px;
  }
`;

const MasonryContainer = styled.div`
  position: relative;
  width: 100%;

  .my-masonry-grid {
    display: flex;
    margin-left: -90px;
    width: auto;

    @media (max-width: 1000px) {
      margin-left: -60px;
    }
  }
  .my-masonry-grid_column {
    padding-left: 90px;
    background-clip: padding-box;

    @media (max-width: 1000px) {
      padding-left: 60px;
    }
  }
`;

const Photographer = styled.div`
  margin-bottom: 90px;

  @media (max-width: 768px) {
    margin-bottom: 45px;
  }

  & img.portrait {
    max-height: 600px;
    object-fit: contain;
  }

  & .details {
    text-align: center;

    & h2 {
      margin: 20px 0 0 0;

      font-size: 36px;
      line-height: 36px;
      letter-spacing: 0.01em;

      @media (max-width: 1300px) {
        font-size: 28px;
        line-height: 36px;
      }

      @media (max-width: 900px) {
        font-size: 26px;
        line-height: 30px;
      }
    }

    & p {
      font-size: 16px;
      line-height: 135%;
      letter-spacing: 0.04em;

      margin: 8px 0 0 0;
    }
  }
`;

const FilteredPhotographyProjectContent = ({
  data,
  setPageType,
  location,
  pageContext,
}) => {
  useEffect(() => {
    setPageType("stills");
  }, []);

  useEffect(() => {
    if (typeof window !== "undefined" && typeof document !== "undefined") {
      const Macy = require("macy");

      const macyInstance = Macy({
        container: `#masonry`,
        columns: 2,
        margin: 90,
        breakAt: {
          1400: 2,
          767: 1,
        },
      });
    }
  }, []);

  const content = data.allPrismicPhotographyProject.edges.map(
    (content, index) => (
      <Photographer
        key={`single_photography_project_${content.node.id}_${index}`}
      >
        <Link
          to={content.node.url}
          state={{
            from: location.pathname,
            section: "filtered",
            tag: pageContext.tag,
          }}
        >
          {content.node.data.vimeo_mp4_file.url !== "" ? (
            <video
              src={content.node.data.vimeo_mp4_file.url}
              muted
              loop
              autoPlay
              playsInline
              preload="metadata"
            />
          ) : (
            <>
              {content.node.data.image_thumbnail.fluid !== null && (
                <img
                  className={ImageOrientation(
                    content.node.data.image_thumbnail
                  )}
                  srcSet={content.node.data.image_thumbnail.fluid.srcSetWebp}
                  src={content.node.data.image_thumbnail.fluid.srcWebp}
                  alt={content.node.data.image_thumbnail.alt}
                  // loading="lazy"
                />
              )}
            </>
          )}
        </Link>
        <div className="details">
          <Link
            to={content.node.url}
            state={{
              from: location.pathname,
              section: "filtered",
              tag: pageContext.tag,
            }}
          >
            <h2>{content.node.data.title.text}</h2>
          </Link>

          <Link to={content.node.data.photographer.document.url}>
            <p className="caslon-doric">
              {content.node.data.photographer.document.data.name.text}
            </p>
          </Link>
        </div>
      </Photographer>
    )
  );

  // const breakpointColumnsObj = {
  //   default: 2,
  //   767: 1,
  // };

  return (
    <>
      <Helmet
        title={`Photography – Park Village`}
        meta={[
          {
            name: "og:title",
            content: `Photography – Park Village`,
          },
          {
            name: "twitter:title",
            content: `Photography – Park Village`,
          },
        ]}
      />

      <Container>
        <FilteredStills />
        <MasonryContainer id="masonry">
          {/* <Masonry
            breakpointCols={breakpointColumnsObj}
            className="my-masonry-grid"
            columnClassName="my-masonry-grid_column"
          > */}
          {content}
          {/* </Masonry> */}
        </MasonryContainer>
      </Container>
    </>
  );
};

const FilteredPhotographyProject = ({ data, location, pageContext }) => (
  <PageTypeConsumer>
    {({ setPageType }) => (
      <FilteredPhotographyProjectContent
        setPageType={setPageType}
        data={data}
        location={location}
        pageContext={pageContext}
      />
    )}
  </PageTypeConsumer>
);

export default withPreview(FilteredPhotographyProject);

export const query = graphql`
  query FilteredPhotography($tag: String!) {
    allPrismicPhotographyProject(
      sort: { fields: last_publication_date, order: DESC }
      filter: { tags: { eq: $tag } }
    ) {
      edges {
        node {
          url
          id
          data {
            title {
              text
            }
            photographer {
              document {
                ... on PrismicPhotographer {
                  id
                  url
                  data {
                    name {
                      text
                    }
                  }
                }
              }
              url
            }
            image_thumbnail {
              alt
              fluid {
                srcWebp
                srcSetWebp
              }
              dimensions {
                width
                height
              }
            }
            vimeo_mp4_file {
              url
            }
          }
        }
      }
    }
  }
`;
