import React from "react";
import { useStaticQuery, graphql, Link } from "gatsby";
import styled from "styled-components";

const Filters = styled.div`
  position: relative;

  z-index: 10;

  & ol {
    position: sticky;
    top: 50px;

    margin: 0;
    padding: 0;

    & li {
      margin: 5px;

      & a {
        color: #1d1d1b;

        font-size: 14px;
        line-height: 16px;
        letter-spacing: 0.56px;

        background-color: transparent;
        transition: 250ms background-color ease;

        &:hover,
        &.active {
          font-family: "Caslon Doric Web Medium", "Helvetica", "Lucida Grande",
            sans-serif;
        }
      }

      @media (max-width: 1000px) {
        margin: 1px 0;

        & a {
          font-size: 12px;
          line-height: 14px;
          text-align: center;
        }
      }
    }
  }

  @media (max-width: 1000px) {
    & ol {
      position: relative;
      top: unset;

      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-column-gap: 0;

      max-width: 400px;
      margin: 0 auto 50px auto;

      & li {
        text-align: center;
      }
    }
  }
`;

const toKebabCase = str =>
  str &&
  str
    .match(/[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g)
    .map(x => x.toLowerCase())
    .join("-");

const uniqueArrayItems = (data, key) => {
  return [...new Map(data.map(x => [key(x), x])).values()];
};

export const FilteredStills = () => {
  const data = useStaticQuery(graphql`
    {
      prismicPhotographers {
        data {
          photographers {
            photographer {
              document {
                ... on PrismicPhotographer {
                  id
                  data {
                    name {
                      text
                    }
                  }
                  url
                }
              }
            }
          }
        }
      }
    }
  `);

  const photographers = data.prismicPhotographers.data.photographers
    .filter(photographer => photographer.photographer.document !== null)
    .map((photographer, index) => (
      <li key={`photographer_tag_${index}`}>
        <Link
          to={photographer.photographer.document.url}
          activeClassName="active"
          className="caslon-doric"
        >
          {photographer.photographer.document.data.name.text}
        </Link>
      </li>
    ));

  return (
    <Filters>
      <ol>{photographers}</ol>
    </Filters>
  );
};
